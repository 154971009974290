import React, { useState, useEffect, Suspense } from "react";
import { isEmail } from "validator";
import Swal from 'sweetalert2'
import { Link, useNavigate} from 'react-router-dom';

export const LoginPage =(props)=>{
    const navigate= useNavigate();

    const [userName,setUserName] = useState('')
    const [password,setPassword]= useState('')
    const [rfc, setRfc] = useState('')
    const [correo, setCorreo]= useState('')
    const [correo_recupera, SetCorreoRecupera] = useState('')
    const [submitted,setSubmitted] =useState(false)
    const [visualizar, setVisualizar]= useState('password')
    const [correomensaje,setCorreomensaje]= useState(true)
    const [rfcvalido,setRfcValido]= useState(false)
    const [correorecu, setCorreorecu] = useState(true)
    const [mostrarmensaje_recupera, setMostrarMensajeRecupera]=useState(false)
    const [mostrarmensaje_correo,setMostrarmensaje_correo]=useState(false)
    const [mostrarrfc,setMostrarRfc] = useState(false)
    const [loggingIn, setLoggingIn]= useState(false)
 
    const handleSubmit=(e)=>{
       
        
        setSubmitted(true)
        setLoggingIn(true)
        navigate('/')
        if (userName && password) {
         
        //userActions.login(userName, password);
         
        }

        e.preventDefault();
        
    }

    useEffect (()=> {
        window.scrollTo(0, 0);
        const query = new URLSearchParams(props);
        const token = query.get('astpacces')
       
       if (token != null){

       // const { dispatch } = props;
        
      
     //   userActions.AutentificarPortar(token);
          
        }

    },[])

    const validarRfc =(rfc)=>{
        var stringRfc = new RegExp("^([A-ZÑ]|\\&)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$");
        rfc = rfc.toUpperCase();
        if (rfc.length >=4){
        if (stringRfc.test(rfc)){
        setRfcvalido(true)
        setMostrarRfc(false)
            //rfc si es valido pasa
        }else {
           
            setRfcvalido(false)
        setMostrarRfc(true)
            //rfc es invalido
        }
    }else{
        setMostrarRfc(false)
    }
    }

    const validarPass =(pass)=>{
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        if(strongRegex.test(pass)){
            return true
        }else{
            return false
        }
            
        
        // Debe contener almenos una letra en minuscula
        // Debe contener almenos una letra en mayuscula
        // Debe contener almenos un numero
        // Debe contener almenos un caracter especial
        // Debe ser de tamaño 8 o mayor
    }

    const alertConfirm =(mensaje, tipo) => {  
        Swal.fire({  
            title: mensaje,  
            type: tipo,
            icon: tipo,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
        });  
    }
    const alertConfirmPAssc =(mensaje) => {  
        Swal.fire({   
            type: 'warning',
            text: mensaje,
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
        });  
    }     
    
    
    const handleC=(id,ed)=>{
       
       if (id =='password'){
           setVisualizar('a')
       }else {
           setVisualizar('password')
       }
    }

    const onChangeRFC =(e) => {  
        setRfc(e.target.value.toUpperCase())
        validarRfc(e.target.value);
    }

    const onChangeCorreo =(e) => {
        setCorreo(e.target.value)
        var correo = e.target.value;
        if (correo.length >=2){
        if (!isEmail(e.target.value)){ 
            setCorreomensaje(true)
            setMostrarmensaje_correo(true)
        }else {
            setCorreomensaje(false)
            setMostrarmensaje_correo(false)
        }
       
    }else {
        setMostrarmensaje_correo(false)
    }
        
    }

   const onChangeCorreoRecupera =(e) => {
       
            SetCorreoRecupera(e.target.value)
    
        var correorecup = e.target.value;
        if (correorecup.length >=2){
        if (!isEmail(e.target.value)){
          
            setCorreorecu(true)
            setMostrarMensajeRecupera(true)
        }else {
           
            setCorreorecu(false)
            setMostrarMensajeRecupera(false)
        }
       
    }else {
      
            setMostrarMensajeRecupera(false)
    }

    }

    const handleChange =(e) => {
        const { name, value } = e.target;
        //this.setState({ [name]: value });
        if(name =="username"){
            setUserName(value)
        }else{
            setPassword(password)
        }
        //username  password
   
    }

    const generarPass= () =>{
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_!#$%&&/())=?¡";
        var string_length = 8;        
        var bandera = false;
        var randomstring = '';

        while (!bandera) { 
            randomstring = '';
            for (var i=0; i<string_length; i++) {
                var rnum = Math.floor(Math.random() * chars.length);
                randomstring += chars.substring(rnum,rnum+1);
            }
    
            bandera = validarPass(randomstring)
        }        

        return randomstring
    }

    const onClickRecuperarPass=()=> {
        var new_pass = generarPass()

        const json = { 
              
                email: '' + correo_recupera + ''
            }

        var requestOptions = {
            method: "POST",
            headers: {
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        };

        fetch(`${process.env.REACT_APP_API_URL}/Account/RequestRecoveryPassword`, requestOptions)
            .then(response => response.json())
            .then(response => {
              
                    if(response.status =="Error") {
                      alertConfirmPAssc("Ocurrió un error al intentar procesar la solicitud, favor de intentar más tarde.")
                    }
                    else {
                        alertConfirm('Recibirás las instrucciones para recuperar tu contraseña en tu correo electrónico.', "success")
                        // alert(props.Status + " " + props.Mensaje);
                    }
            })
    }

    const onClickYaSoyCliente=()=> {
        
        const json = { 
                correo: '' + correo + '',
                rfc: '' + rfc + ''
            }        

        var requestOptions = {
            method: "POST",
            headers: {
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        };

        fetch(`${process.env.REACT_APP_API_URL}/RegistroRfc/agregarRegistroRfc`, requestOptions)
            .then(response => response.json())
            .then(response => {
              

                    if(response.status=="Error") {
                        alertConfirm('Este correo ó rfc ya están en uso!',"warning")
                    }
                    else if (response.status=="Exitoso") {
                        alertConfirm('Te notificaremos en caso de que tu solicitud haya sido aceptada',"success")
                        // alert(props.Status + " " + props.Mensaje);
                    }
            })
    }


    return (  
        <div className="container-fluid">

            <div className="row movila">

                <div className="col-sm-6" id="login_img">
                    <div className="color2 d-flex justify-content-center align-items-center slider img_login">
                        <img className="img-fluid" src={'/img/img_portal/ExteriorFoto.png'} alt="logo" />
                    </div>
                </div>
                 
                <div className="col-sm-6">
                    <br /><br /><br /><br />
                    <div className="login-inc">
                        <img className="img-center img-fluid" src={'/img/img_portal/MiHVAC.PNG'} alt="logo2" />
                        <form className="form-group" onSubmit={handleSubmit}>
                            <div className={'form-group' + (submitted && !userName ? ' has-error' : '')}>
                                <label className="usuario-lab" htmlFor="username"><span>Usuario</span></label> <br />
                                <input type="text" className="form-control-u" name="username" placeholder="usuario@gmail.com" value={userName} onChange={handleChange} />
                                {submitted && !userName &&
                                    <div className="help-block">Usuario es Requerido</div>
                                }
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <br />
                                <label className="lal-contraseña" htmlFor="password">Contraseña</label> <br />
                                <div class="input-group input-pass">
<input type={visualizar} value={password} onChange={handleChange} name="password"class="form-control sinborde-right" placeholder="************"  aria-describedby="btnGroupAddon"/>

<div class="input-group-prepend">
<div className="lupa-login"> <img onClick={(e) => handleC(visualizar, e)}  className="img-login-pass" src={'/img/img_portal/ico-contraseña.svg'} /></div>
</div>
</div>
{submitted && !password &&
                                    <div className="lab-contraseña">Contraseña es Requerida</div>
                                }                         
                                <br />
                               
                                <br />

                                <label className="lbl-recordar"><input type="checkbox" id="cbox1" value="first_checkbox" /> Recordar mi usuario</label>
                            </div>
                            <br />
                            <div className="form-group">
                                <button className="btn btn-primary"><label className="lbl-portal"><span>Entrar al Portal</span></label> <img className="icono-entrada" src={'/img/img_portal/Trazado 109.svg'} alt="ic-ent" /> </button>
                                {loggingIn &&
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                            <br/>
                            <div className="form-group d-flex justify-content-center">
                                <label className="recordar-contraseña" data-toggle="modal" data-target="#modalClienteRecuperar">¿Olvidaste tu contraseña?</label>                                    
                            </div>
                            <br />
                            
                            <div className="form-group d-flex justify-content-center">
                                    <label className="unirte separar-button-unir">¿Quieres Unirte?   </label>
                                    <button type="button" className="btn btn-secondary btn_style_login width_80 col-4" data-toggle="modal" data-target="#modalClienteLogin">Ya soy cliente</button>
                             </div>
                                
                                
                            

                        </form>



                    </div>                        

                </div>

            </div>

            <div className="modal fade" id="modalClienteLogin" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-servicios" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal_title">Ya soy cliente</h3>                                
                        </div>
                        <div className="modal-body">
                            <div>
                                <form>
                                    <div className="form-group">
                                        <label for="input_rfc">RFC</label>
                                        <input type="text" className="form-control" value={rfc} onChange={onChangeRFC} id="input_rfc" aria-describedby="RFC" placeholder="XAXX010101000"/>
                                        {mostrarrfc==true &&
                                    <div className="help-block">Favor de ingresar un RFC valido</div>
                                }  
                                 {rfcvalido==true &&
                                    <div className="help-block"><img src={'/img/ico/done_black_18dp.svg'}/> RFC válido</div>
                                }    
                                    </div>
                                    <div className="form-group">
                                        <label for="input_correo">Correo</label>
                                        <input type="text" className="form-control" value={correo} onChange={onChangeCorreo} id="input_correo" aria-describedby="Correo" placeholder="Escribe tu correo"/>
                                        {mostrarmensaje_correo==true &&
                                    <div className="help-block">Favor de ingresar un correo valido</div>
                                }      {correomensaje==false &&
                                    <div className="help-block"><img src={'/img/ico/done_black_18dp.svg'}/>Correo válido</div>
                                } 
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="astra-button r-boton-gris" data-dismiss="modal">Cerrar</button>
              {correomensaje==false && rfcvalido==true &&
               <button className="astra-button boton-rojo" onClick={()=>onClickYaSoyCliente()} data-dismiss="modal">Enviar</button>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalClienteRecuperar" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-recuperar" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal_title">Recuperar Contraseña</h3>                                
                        </div>
                        <div className="modal-body">
                            <div>
                                <p className="label_recuperar_pass">
                                    Ingresa el correo electrónico asociado a la cuenta, te enviaremos las instrucciones para restablecer la contraseña.
                                </p>
                                <form>
                                    <div className="form-group">
                                        {/* <label for="input_email">Correo electrónico</label> */}
                                        <input type="text" className="form-control" value={correo_recupera} onChange={onChangeCorreoRecupera} id="input_email" aria-describedby="email" placeholder="Escribe tu Correo"/>
                                {mostrarmensaje_recupera==true &&
                                    <div className="help-block">Favor de ingresar un correo valido</div>
                                }      {correorecu==false &&
                                    <div className="help-block"><img src={'/img/ico/done_black_18dp.svg'}/>Correo válido</div>
                                } 
                                    </div>                                        
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="astra-button r-boton-gris" data-dismiss="modal">Cerrar</button>
                            {correorecu==false &&
                      <button className="astra-button boton-rojo" onClick={onClickRecuperarPass} data-dismiss="modal">Enviar</button>}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        
    )
}