
import Swal from 'sweetalert2'
import { Routes, Route, useLocation, useNavigate, Navigate, Link } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { addPayLink } from 'services/addPayLink'

const ProductComponent = ({item, category}) => {

  const navigate = useNavigate()
  const {setDetailItem} = useAuth()


  const nameProduct =(name)=>{
    let i=0;
    while(i==0){
        if(name.includes("-")){
            
            name=name.replace("-", " ");
        }else{
            i=1
        }
    }
    return name;
  }
    return ( 
       <div key={item.id}  className="card"  >   
    <Link  to={`/detalle/${item.category?.nameSpanish}/${nameProduct(item.name)}/${item.state?.name}/${item.id}`} style={{textAlign:'center'}} onClick={()=> setDetailItem(item)}>
          <img src={item.imageUrl? item.imageUrl: '/img/imagendummy.png'} className="img-produc "   alt="..."/>
  <div class="card-body" style={{textAlign:'center'}}>
    
    <h3 class="card-title  text-produc">{item.name}</h3>
    <h6 class="card-title" style={{ color: "green" }}>
    Existencias {item.stock}
                  </h6>
                  <h6 class="card-title  text-produc" style={{ color: "orange" }}>
                    {item.description}
                  </h6>
                  <h6 class="card-title" style={{ color: "red" }}>
                    Ubicación {item.state?.name}
                  </h6>
                  <h6 class="card-title" style={{ color: "black" }}>
                    Entrega de 3 a 15 días
                  
                  </h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg> Venta | Renta
                  

  </div>
  
   </Link>

  <div class="card-body " style={{textAlign:'center'}}>
  <a href={"/contact/" + nameProduct(item.name)}>
                  <button style={{ width: "200px" }} className="btn btn-primary">
                    Cotizar ahora
                  </button>
                </a>
  </div>
      </div>
    )
}

export default ProductComponent