import { Tabs } from "./tabs/Tabs"
import { useState, useEffect } from "react"
import { Banners } from "./banners/Banners"
export const MultiServices = ({list, defaultItem, defaultTitle})=>{
    const [sectionActive, setSectionActive]= useState(defaultItem)
    const [title, setTitle]= useState('')
    const [description, setDescription]= useState('')
    const [url, setUrl]= useState('')

    const changeSection = (opc)=>{
       const section = list.filter((item)=> item.id==opc)
      // window.alert(JSON.stringify(section))
      // window.alert(section)
      if(section){
      
       setTitle(section[0].title)
       setDescription(section[0].description)
       setUrl(section[0].url)
       setSectionActive(opc)
      // window.alert(JSON.stringify(section))
        }
    }

    useEffect(()=>{
        changeSection(sectionActive)

    },[sectionActive])

    useEffect(()=>{
        changeSection(sectionActive)
    },[])
    
    return (
        <>
        <div className="container">
            <Tabs sectionActive={sectionActive} list={list} setSectionActive={setSectionActive}/>
        </div>
        <Banners title={title} description={description} url={url} sectionActive={sectionActive} list={list} titleSection={defaultTitle}/>
        </>
    )
}