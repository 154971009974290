import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from 'router'
//Boostrapp

import { AuthProvider } from 'auth/context/AuthProvider'
function App () {
  return (
    <AuthProvider>
    <BrowserRouter>
    
       <Router />

    </BrowserRouter>
    </AuthProvider>
  )
}

export default App
