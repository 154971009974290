import ReactDOM from 'react-dom/client'

import App from './App'

import 'style_Sitio.css'
import 'style_responsive.css'
import 'style.css'
import 'stylennarvar.css'
import 'chat.css'
import './StyleGlobal.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'bootstrap/dist/js/bootstrap.bundle'

//import 'style_configuracion_Navbar_Footer.css'



const Root = () => (
  
      <App />
)

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<Root/>);