export const servicesIsa = [
    {
        id:1,
        title:'Venta - Renta plataformas de elevación',
        description:'Ofrecemos los mejores equipos a los mejores costos y de calidad',
        url: '/contact/plataformas-elevacion',
        urlImg:'/img/img_servicios/platataformas.jpeg'
    },
    {
        id:2,
        title:'Venta - Renta Montacargas',
        description:'Los mejores montacargas a los mejores precios.',
        url: '/contact/montacargas',
        urlImg:'/img/img_servicios/montacargas.jpg'
    },
    {
        id:3,
        title:'Construcción',
        description:'Nuestros servicios para el area de construcción, para lograr el reto de tu proyecto.',
        url: '/contact/construccion',
        urlImg:'/img/img_servicios/construccion.jpg'
    },
    {
        id:4,
        title:'Servicio Técnico',
        description:'Que tu equipo no te deje en mal, contamos con los mejores técnicos para revisar tus equipos.',
        url: '/contact/servicio-tecnico',
        urlImg:'/img/img_servicios/servicio.jpeg'
    },
    {
        id:5,
        title:'Translados',
        description:'En Blocks Baratos, nos especializamos en el traslado seguro y eficiente de plataformas para tus proyectos industriales y de construcción. Con equipos especializados y un enfoque en la seguridad, garantizamos un servicio confiable y sin contratiempos.',
        url: '/contact/translados',
        urlImg:'/img/img_servicios/trailer.jpg'
    }
]