import React,{useEffect, useState} from "react";
import ProductComponent from "components/ProductComponent/ProductComponent";
import Loading from "components/loading/Loading";

const ListProduct = ({category}) => {
  
//    const history = useHistory();
    const [newProducts, setNetProducts] = useState([])
    const [loading,setLoading] = useState(false)

    useEffect(()=>{

        getProducts(category)

    },[])

    const getProducts =async(id)=>{
        setLoading(true)
        let result = await fetch_products(id)
            setNetProducts(result.products)
            setLoading(false)
    }
  

    return (
        <>
            <Loading loading={loading}/> 
            <div class="row">
               
            {newProducts&& 
            newProducts.map((item)=> (
                (item.imageUrl? 
                <div class="col-sm-3 " style={{paddingTop:'20px'}} key={item.id}>
                 <ProductComponent category={category} item={item}/>
                </div> :""
                ))

            )
          
            } 
           
            </div> 
              
                  
        </>
    )
}

export default ListProduct


const fetch_products = async (categoryId) =>
    (await fetch(`${process.env.REACT_APP_URL}search-engine/get-filters-genie-products?categoryId=${categoryId}&stateId=20&page=1`)).json()