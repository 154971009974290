import React, { useState, useEffect, Suspense } from "react";
import {Navbar} from "ui/components/navbar"
import { Footer } from "ui/components/footer";
import { sendMailService } from "services/sendMailService";
import Loading from "components/loading/Loading";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { Breamcrumb } from "components/breamcrumb";
import { useParams } from "react-router-dom";



export const Contact = () =>{
    const {service}= useParams()
    const [name, setName ] = useState('')
    const navigate = useNavigate()
    const [mail, setMail ] = useState('')
    const [subject, setSubject ] = useState( service? service.replace('-',' '):'')
    const [message, setMessage ] = useState('')
    const [phone, setPhone] = useState('')
    const [valide, setValide] = useState(false)
    const [valideMail, setValideMail]= useState(false)
    const [valideName, setValideName]= useState(false)
    const [valideSubject, setValideSubject]= useState(false)
    const [loading, setLoading]= useState(false)

    const isValidEmail=(email) => {
        return /\S+@\S+\.\S+/.test(email);
      }

      const nameProduct =(name)=>{
        let i=0;
        while(i==0){
            if(name.includes("-")){
                
                name=name.replace("-", " ");
            }else{
                i=1
            }
        }
        return name;
      }
    
    const sendContact = ()=>{
        setLoading(true)
     //   console.log( name + mail + subject + message)
    
     if(mail.length===0) setValideMail(true)
     if(name.length===0) setValideName(true)
     if(subject.length ===0) setValideSubject(true)

       if(subject.length >0 && !valideMail && name.length>0 ){
       
        sendMailService(mail,name,message,phone, subject).then(({ isSuccess, response, message }) => { 

            setLoading(false)
            Swal.fire({title:"Pronto un agente, se contactará contigo.",icon:"success", showConfirmButton: false,timer: 1000,
            
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }});
            navigate('/')
        })
       
       // window.alert('Se envio alerta')
       }else {
        setLoading(false)
       }
    }
    useEffect(()=> {if(name.length>0) setValideName(false) },[name])
    useEffect(()=>{if(subject.length>0) 
       
         // setSubject(subject.replace('-',' '))
        
      setValideSubject(false)},[subject])
    useEffect(()=>{
  
        if(!isValidEmail(mail) && mail.length>1 ){
            setValideMail(true)
            //window.alert(mail)
        }else{
            setValideMail(false)
        }

    },[mail])
    useEffect(()=>{
      
      let names = nameProduct(subject)
      setSubject(names)
      window.scrollTo(0,0);},
      
      [])

    return ( 
    <>
     <Navbar/>
        <div className="container" style={{paddingTop:'120px'}}>
     
        {/* <Breamcrumb name={'INICIO'} rute={'/home'} name2={'PRODUCTOS / COMPUTADORAS / LAPTOPS'}/> */}
         <h1 className="section-title"> Contáctanos</h1>
      
        <br/>
       
        <div className="mb-3">
         
         <input type="text" className="form-control" id="exampleFormControlInput1" onChange={(e)=>{setName(e.target.value)}} placeholder="Nombre completo" />
       { valideName && <label style={{color:'gray'}} > *Campo Obligatorio</label>}
        </div>
        <div className="mb-3">
       
         <input type="email" className="form-control" id="exampleFormControlInput1" onChange={(e)=>{setMail(e.target.value)}} placeholder="Correo electrónico" />
         { valideMail && <label style={{color:'gray'}} > *Campo Obligatorio</label>}
         {valideMail && <label style={{color:'gray'}} > *El correo tiene un formato incorrecto, ejemplo: ejemplo@example.com</label> }
        </div>
        <div className="mb-3">
       
       <input type="text" className="form-control" id="exampleFormControlInput1" value={subject} onChange={(e)=>{setSubject(e.target.value)} } placeholder="Como podemos ayudarte?" />
       { valideSubject && <label style={{color:'gray'}} > *Campo Obligatorio</label>}
      </div>
      <div className="mb-3">
       
       <input type="text" className="form-control" id="exampleFormControlInput1" onChange={(e)=>{setPhone(e.target.value)} } placeholder="Teléfono" />
      </div>
    <div className="mb-3">
     
        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>{setMessage(e.target.value)}} placeholder="Mensaje"></textarea>
    </div>

    <div className="mb-3" style={{textAlign:"center"}}>
     
     <button className="btn btn-primary" onClick={()=>{sendContact()} } style={{width:'200px', color:'#f1f1f1'}} >Enviar </button>
    </div>




    <h3 className="section-title">Ubicación</h3>

<br/>

<div className="container">  
   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3594.3963189767387!2d-100.33791882402762!3d25.72440431014409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866295ae68dc3349%3A0x8fe2c1e7b2b5736!2sIsa%20Solutions%20Rm!5e0!3m2!1sen!2smx!4v1707164380481!5m2!1sen!2smx" width="100%" height="450px" 
   style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<div className="container">
   <p>Horario de atención: <span> Lunes - Viernes  (9am - 6 pm) </span></p>
   <p>Teléfonos:
   <a href={'https://api.whatsapp.com/send?phone=5218130809602&text=Hola%2C%20buen%20d%C3%ADa%F0%9F%98%80'} 
            style={{textDecoration:'none', color:'gray'}}
            target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="gray" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg>
             { ' (81)-3080-9602'}
							</a>

   </p>
</div>
         



            </div>
        <Footer/>
    
        <Loading loading={loading}/>
    </>
        
    )
}