import React, { useState, useEffect, Suspense } from "react";
import { Link} from 'react-router-dom';
import { authHeader_get } from "_helpers";
export const Footer = ()=>{
  const [DetalleContacto, setDetalleContacto] = useState([])
  const [Error, setError] = useState(false)
  const nombre =JSON.parse(localStorage.getItem('user'));
  const [idContratista, setidContratista]= useState(nombre ? nombre.idContratista:0)
  const [tipoBd, settipoBd]= useState(nombre ? nombre.tipoBd : 'climas')
  const [typeRol, setTypeRol]=useState(nombre?  nombre.userRol :'')
  const [verSaldosyMovimientos, setVerSaldosyMovimientos]= useState(nombre ? nombre.Accesos_Directos_Saldos_y_Movimientos :0 )
  const [Cotizar, setCotizar] =useState(nombre ? nombre.Accesos_Directos_Cotizaciones :0)
  
    return (
        <div className="footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-2">
              <div class="widget-a">
                <div class="w-header-a">
                      {/* <img className=" img-fluid" src={'/img/img_portal/Logorefi.png'} alt="logo2" /> */}
                      <p class="w-text-a color-text-a" style={{color:'white'}}>
                      Blocks Baratos</p>
                                </div>
                       
                <div class="w-body-a">
                  <p class="w-text-a color-text-a">

                  </p>
                </div>
              </div>
            </div>
        
            <div class="col-sm-12 col-md-3 section-md-t3">
              <label className="footer-pag">Conócenos</label>
              <div class="w-body-a">
                <ul class="list-unstyled">
                  <li className="lista-elem" >
                    <Link className="lista-elem-in" to="/">Inicio</Link>
                  </li>
                  <li className="lista-elem">
                    <Link className="lista-elem-in" to="/services">Servicios</Link>
                  </li>  
                  <li className="lista-elem">
                    <Link className="lista-elem-in" to="/">Blog</Link>
                  </li>  
                  <li className="lista-elem">
                    <Link className="lista-elem-in" to="/about">Nosotros</Link>
                  </li>  
                  
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 section-md-t3">
              <div class="widget-a">
                <div class="w-header-a">
                  <label className="footer-pag">Legal</label>
                </div>
                <div class="w-body-a">
                  <ul class="list-unstyled">
                    <li className="lista-elem-in">
                      <Link className="lista-elem-in" to="/">Aviso de Privacidad</Link>

                    </li>
                    <li className="lista-elem-in">
                      <Link className="lista-elem-in" to="/">Términos y Condiciones</Link>

                    </li>

                    
                   {/* <li className="lista-elem-in">Términos y condiciones</li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 section-md-t3">
            <div class="widget-a">
                <div class="w-header-a">
                  <label className="footer-pag">Contáctanos</label>
                </div>
               
                <div class="w-body-a">
                  <ul class="list-unstyled">
                   
                    <a href={'mailto:contacto@isasolutionsrm.com'} style={{textDecoration:'none', color:'white'}}> 
            <p><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
              
                 {' Ventas Blocks Baratos'}</p>
            </a>
                   
                  </ul>
                </div>
                
              </div>
              
            </div>
          </div>
          <section className="p-3 pt-0">
        <div className="row d-flex align-items-center">
  
          <div className="col-md-7 col-lg-8 text-center text-md-start">
            <div className="p-3 title-copyright">
           @ 2024 Blocks Baratos | Diseñado por:
              <a className="title-copyright" href="https://isasolutionsrm.com/" 
                 > ISA SOLUTIONS RM</a
                >
            </div>
          </div>
        </div>
      </section>
        </div>
 

      </div>
    )
}