import { useNavigate } from "react-router-dom"

export const Question = ()=>{
const navigate = useNavigate()

    return (
        <>

        <div className="container" style={{paddingTop:'25px'}}>
            <div className="row">
                <div className="col-md-6">
                <h2 className="section-title section-center">
            ¿Estás preparado para una nueva evolución con tus proyectos?
            </h2>
            <p>Acelera el éxito de tus proyectos, que nuestros líderes en plataformas de elevación y soluciones para tus proyectos de construcción.</p>
                </div>
                <div className="col-md-6 section-center">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                <button type="button" onClick={()=>{navigate('/contact/plataforma-de-elevacion')}} className="btn active btn-button-emp" data-bs-toggle="button" aria-pressed="true">Contáctanos</button>
                </div>
            </div>
            

        </div>
        
        
        </>
    )
}