import React, { useState, useEffect, Suspense } from "react";
import { Link } from 'react-router-dom';


export const  Compras = ()=>{
   const[listaCompras, setListaCompras]=useState([])
   const[value, setValue]= useState(5)
   const [combo, setCombo]=useState(-1)
   const [error,setError]= useState(false)
   const [loadingPdf, setLoadingPdf] =useState(false)  
   const [numordenpdf,setNumordenpdf]=useState(0)

   const alertConfirmAPro=(text)=> {  
    Swal.fire({  
        title: 'Aviso!', 
        type: "warning",
        text: text,
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true
    });  
  } 

  const getPdfUrl=(numorden)=>
  {
    const  nombre  =JSON.parse(localStorage.getItem('user'));
    return `${process.env.REACT_APP_API_URL}/PDFCompras/`+nombre.tipoBd +`,` + nombre.idContratista +`,`+ numorden;
  }


   
  const handleStatus =(combo)=>{
     setCombo(combo.target.value)
     cargardatos(5,combo.target.value);
      
     combo.preventDefault();
  } 

  const handleChange= (event) =>{
	
    setValue(event.target.value)
    
    if (event.target.value >=0){
  cargardatos(event.target.value, -1);}else{
    alert('No hay resultados negativos');
   }
	event.preventDefault();
  }
  const cargardatos=(value,com)=>{
    let requestOptions = authHeader_get();
    const  nombre  =JSON.parse(localStorage.getItem('user'));
    fetch(`${process.env.REACT_APP_API_URL}/Compras/`+nombre.tipoBd +',' + value + ","+ nombre.idContratista +","+ com +",1" ,requestOptions )
    .then(response => response.json())
    .then( response => {
         
          setListaCompras(response)
          setError(true)
      })
      .catch( 
          error => console.log("ultimas compras" + error), setError(false)
             )
  }
    return (
<div className="card-componente dashboard-misinfo-ultimascompras">
      <div className="card-componente-header-compras">
          <div className="card-componente-titulo">Pedidos recientes</div>
          <div className='col-ms-6'>
            {/*<span className="card-componente-label"> Mostrar  </span>
            <input  min="1" max="10" step="1" type="number" className='input-card-incre' value={this.state.value}
          onChange={this.handleChange}  placeholder="5" /> */}
           
              <select  className='separar-button-sstua' value={combo}  onChange={handleStatus}>
                  <option value="-1" disabled>Estatus</option>
                  <option value="-1">Todos</option>
                  <option value="3">Entregado</option>
                  <option value="2">Parcialmente entregado</option>
                  <option value="1">Autorizado</option>
                  <option value="0">Por Autorizar</option>
              </select>
             
            </div>
          
            <Link to="/Pedidos"><div className="label-en-rojo">Ver todo</div></Link>
      </div>
      
      <div className="card-componente-tables table-responsive">
      <table>
         <thead className='card-align'>
            <tr>
          <th>Pedido</th>
          <th  className ="fecha-th priority-4">Fecha</th>
          <th  width={"20%"} className="money">Importe</th>
          <th>Estatus</th>
           </tr>
          </thead>
           <tbody className='card-align'> 
        { Error==true &&
        ListaCompras.map(emp => (
          <tr key={emp.numorden}>
            <td>{emp.numorden}</td>
            <td className="fecha-rd priority-4">{emp.fecha}</td>
           
            <td width={"20%"} className="money-rd">{'$ ' +emp.importe}</td>
            <td className="estatus-rd"> 
            {emp.descripcion=='ENTREGADO' &&  <div className="label-en-verde"> {emp.descripcion} </div>}
            {emp.descripcion=="Entregado" &&  <div className="label-en-verde"> {emp.descripcion} </div>}
            {emp.descripcion=='AUTORIZADO' && <div className="label-en-verde"> {emp.descripcion} </div>}
            {emp.descripcion=="Autorizado" && <div className="label-en-verde"> {emp.descripcion} </div>}
            {emp.descripcion=='Parcialmente Entregado' && <div className="label-en-amarillo"> {emp.descripcion} </div>}
            {emp.descripcion=="PARCIALMENTE ENTREGADO" && <div className="label-en-amarillo"> {emp.descripcion} </div>}
            {emp.descripcion=='Por Autorizar' && <div className="label-en-rojo"> {emp.descripcion} </div>}
            {emp.descripcion=="POR AUTORIZAR" && <div className="label-en-rojo"> {emp.descripcion} </div>}
            </td>
            <td>
              
            {
							 parseInt(emp.importe) == 0 ?																											
								<div className="label-en-gris"><i class="fa fa-download separardd hover_rojo_img" 
								data-toggle="tooltip" title="Descargar compra"
								aria-hidden="true"
									onClick={() =>
										alertConfirmAPro("No se puede procesar la descarga del pdf, ya que este número de orden está en $0")
									}
									></i> </div>
								:
								<a href={getPdfUrl(emp.numorden)} ><i class="fa fa-download separardd hover_rojo_img"
									data-toggle="tooltip" title="Descargar compra"
									aria-hidden="true"
									></i></a>
								
							}
                {
							 parseInt(emp.importe) == 0 ?																											
								<div className="label-en-gris"><i class="fa fa-eye separardd hover_rojo_img" 
								data-toggle="tooltip" title="Ver detalle de compra"
								aria-hidden="true"
									onClick={() =>
										alertConfirmAPro("No puedes ver el detalle, por que no tiene productos")
									}
									></i> </div>
								:
                <Link
                to={{pathname: "/Pedidodetalle",  state: {   Numero_pedido: emp.numorden }, }} 	data-toggle="tooltip" title="Ver detalle de compra" ><i class="fa fa-eye separardd hover_rojo_img" aria-hidden="true"></i>
              </Link>
							}
             

            {loadingPdf==true && emp.numorden == numordenpdf && 
            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            }                      
            </td>
            <td></td>
            <td></td>
            </tr>
          ))
          
          }        
        </tbody>                           
         </table>
      </div>

      
      </div>
    )
}