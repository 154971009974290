import React, { useState, useLayoutEffect } from 'react'
import './Loading.css'


const Loading = ({loading}) => {
   // const [show, setShow] = useState(true)
    const styl= 'container-homess containerss hidess'
    const styl2= 'container-homess containerss'
    return(
        <div className={ loading? styl2 : styl  }>
        <p style={{marginLeft:'-10px'}}></p>
        <div class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
        <br/>
        <p style={{marginLeft:'0px'}}>Espere por favor</p>
      </div>
    );
}; 

export default Loading;