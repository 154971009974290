import { Navbar } from "ui/components/navbar";
import { Footer } from "ui/components/footer";
import React, { useEffect, useState } from "react";
import { Breamcrumb } from "components/breamcrumb";
import { Navigate, json, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import "./DetailGenie.css";
import Swal from "sweetalert2";
import { getMetaTags } from "helpers/getMetaTags";
import { getProduct } from "services/getProduct";
import Loading from "components/loading/Loading";
export const DetailGenie = (props) => {
  const { detailItem, setDetailItem } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { id } = useParams();

  const getProductDetail = async (id) => {
    setLoading(true);
    const result = await fetch_product(id);
    if (result) {
      
      setLoading(false);
      setDetailItem(result);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) getProductDetail(id);
  }, []);

  useEffect(() => {
    if (detailItem) {
      getMetaTag();
    }
  }, [detailItem]);
  const getMetaTag = () => {
    let articule = "Sección";
    let urlPresentation = `${process.env.REACT_APP_URL_FRONT}img/imagendummy`;

    //detalle/Manipuladores%20telescópicos/GTH%20636/Nuevo%20León/b9189886-b38a-4166-b9b0-ba8aeb4dff17

    let urlPage = `${process.env.REACT_APP_URL_FRONT}detalle/${detailItem.category?.nameSpanish}/${nameProduct(detailItem.name)}/${detailItem.state?.name}/${detailItem.id}`;
    let title = "BLOCKS BARATOS | " + detailItem.name;
    let description = `${detailItem.description}`;
    //venta%20-%20renta/Elevadores%20de%20personas/2/todo%20méxico/0
    let urlAlternate = `${process.env.REACT_APP_URL_FRONT}venta/${detailItem.category?.name}/${detailItem.category?.id}/${detailItem.state?.name}/${detailItem.state?.id} `;
    let urlCanonical = `${process.env.REACT_APP_URL_FRONT}renta/${detailItem.category?.nameSpanish}/${detailItem.category?.id}/${detailItem.state?.name}/${detailItem.state?.id} `;
    getMetaTags(
      articule,
      urlPresentation,
      urlPage,
      title,
      description,
      urlAlternate,
      urlCanonical
    );
  };
  const nameProduct = (name) => {
    let i = 0;
    while (i == 0) {
      if (name.includes("-")) {
        name = name.replace("-", " ");
        name = name.replace("/", " ");
      } else {
        i = 1;
      }
    }
    return name;
  };

  return (
    <>
      <Navbar />
      <Loading loading={loading} />

      {detailItem && (
        <div className="container" style={{ paddingTop: "80px" }}>
          <Breamcrumb
            name={"PRODUCTOS"}
            rute={"/genies/pros/" + detailItem.category?.name}
            name2={"FICHA DEL PRODUCTO"}
          />
          <h1 className="section-title"> Modelo {detailItem.name} </h1>
          <br />

          <br />
          <div className="container ">
            <div className="row row-r-25 ">
              <div className="col-md col-r-25 ficha-contenedor-imagen">
                <img
                  className="card-img"
                  src={
                    detailItem.imageUrl
                      ? detailItem.imageUrl
                      : "/img/imagendummy.png"
                  }
                />
              </div>
              <div className="col-md col-r-25 ficha-contenedor-imagen-descripcion">
                <div>
                  <div className="ficha-info-precio">
                    <div className="label-en-gris"></div>
                  </div>
                </div>

                <div className="ficha-info-block">
                  <div className="card-componente-subtitulo" style={{color:'red'}}>Ubicación: {detailItem.state?.name}</div>
                  <div className="card-componente-subtitulo" style={{color:'gray'}}>Categoria: {detailItem.category?.nameSpanish}</div>
                  <div className="card-componente-subtitulo">
                    <div className="ficha-info-disponibilidad">
                      <span className="span-disponibilidad-disponible">
                        Disponible: {detailItem.stock} artículos
                      </span>
                    </div>
                  </div>
                  <div
                    className="card-componente-subtitulo "
                    style={{ color: "rgba(var(--bs-link-color-rgb)" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-bag"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                    </svg>{" "}
                    Venta | Renta
                  </div>
                  <br />
                  <div
                    className="card-componente-subtitulo "
                    style={{ color: "rgba(var(--bs-link-color-rgb)" }}
                  >
                     <h6 class="card-title" style={{ color: "orange" }}>
                    Entrega de 3 a 15 días
                  
                  </h6>
                    </div>
                </div>

                <div className="ficha-info-block">
                  <div className="card-componente-subtitulo">
                    Información del Producto
                  </div>
                  <div className="ficha-info-texto"> {detailItem.description} </div>
                </div>
                <br />
                <div>
                  {/*<button onClick={(e) => this.handleClickButtonC(dynamicData.cveinsumo, e)} className="productos-content-main-grid-producto-boton-button-ficha">*/}
                </div>

                <div>
                  {/* <button  
                                    id="liveToastBtn" className="btn btn-primary" onClick={()=>sendCart()} style={{width:'200px'}}>
                                        <i class="fa fa-plus" aria-hidden="true"> </i>
                                        Añadir al carrito</button> */}

                  <a href={"/contact/" + nameProduct(detailItem.name)}>
                    <button
                      style={{ width: "200px" }}
                      className="btn btn-primary"
                    >
                      Cotizar ahora
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div class="col-md-12"></div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

const fetch_product = async (productId) =>
  (
    await fetch(
      `${process.env.REACT_APP_URL}search-engine/get-product-detail-gen?id=${productId}`
    )
  ).json();
