import React, { useEffect, useState } from "react";
import { Infinite } from "components/infinite/Infinite";
const ListProductAmazon = () => {
  //    const history = useHistory();


  return (
    <>
      <div class="row">
        <Infinite />
      </div>
    </>
  );
};

export default ListProductAmazon;
