import React,{ useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import PropTypes from 'prop-types'

export const AuthProvider = ({ children }) => {
 
  const tmpIsLogged = window.localStorage.getItem('user') !== null
  const [isLogged, setIsLogged] = useState(tmpIsLogged)
  const [search, setSearch]= useState('')
  const [onCloseCallback, setOnCloseCallback] = useState(null)
  const [openAlert, setOpenAlert] = useState({
    open:false, type:'',message:'' 
  }) 
  const [detailItem, setDetailItem]= useState(null)

  const getUserData = () => {
    const rawData = window.localStorage.getItem('user') || '{}'
    const userData = JSON.parse(rawData)

    return userData
  }
  const data =getUserData();
  const nombre =  isLogged ? data : '{}'
 
  const [typeRol, setTypeRol]=useState(nombre!='{}'?  nombre.userRol :'')
  const [Accesocarrito, setAccesoCarrito] =useState(nombre!='{}'? nombre.Accesos_Directos_Ver_Carrito :0)
  const [ControlAutorizarCotizaciones, setControlAutorizarCotizaciones]=useState(nombre!='{}'? nombre.Accesos_Directos_Control_de_Cotizaciones:0)
  const [verSaldosyMovimientos, setVerSaldosyMovimientos]= useState(nombre!='{}'? nombre.Accesos_Directos_Saldos_y_Movimientos :0 )
  const [Cotizar, setCotizar] =useState(nombre!='{}'? nombre.Accesos_Directos_Cotizaciones :0)
  const [AltaUser, setAltaUser]= useState(nombre!='{}'? nombre.Alta_Usuarios_Dar_alta_de_usuario :0)
  const [Configura, setConfigura]= useState(nombre!='{}'? nombre.Configuracion_Configuracion :0)
  const [Acceso_favorito, setAcceso_favorito]= useState(nombre!='{}'? nombre.Accesos_Directos_Favoritos :0)
  const [userRol, setUserRol]= useState( nombre!='{}'? nombre.userRol :0 )
  const [email, setEmail]= useState(nombre!='{}'? nombre.email  : '')
  const [tipoBd, settipoBd]= useState(nombre!='{}'? nombre.tipoBd : 'climas')
  const [idContratista, setidContratista]= useState(nombre!='{}'? nombre.idContratista:0)
  const [usuario, setUsuario]= useState(nombre!='{}'? nombre.usuario : '')
  const getToken = () => {
    const rawData = window.localStorage.getItem('token') || '{}'
   // const userData = JSON.parse(rawData)

    return rawData
  }

  const setStorage = (key, data) => {
    window.localStorage.setItem(`_${key}`, JSON.stringify(data))
  }

  const getStorage = (key) => {
    const rawData = window.localStorage.getItem(`_${key}`) || '{}'

    const storageData = JSON.parse(rawData)
    return storageData
  }

  const logout = () => {
   // setListFavorites([])

    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('myCart');
    window.localStorage.clear()

    setIsLogged(false)
  }

  const getUsername = () => {
    // eslint-disable-next-line camelcase
    const { first_name } = getUserData()

    // eslint-disable-next-line camelcase
    const userName = (first_name.trim().length > 0) ? first_name : 'Door'

    return userName
  }
   const [carritoItems,setCarritoItems] = useState(null)
  /*useEffect(() => {
    window.localStorage.setItem('__listFavorites', JSON.stringify(listFavorites))
  }, [listFavorites])*/
  const [searchKeyword, setSearchKeyword]= useState('')

  const valuesProvider = {
    isLogged,
    searchKeyword, setSearchKeyword,

    setIsLogged,detailItem, setDetailItem,
    typeRol, setTypeRol,
    Accesocarrito, setAccesoCarrito,
    ControlAutorizarCotizaciones, setControlAutorizarCotizaciones,
    verSaldosyMovimientos, setVerSaldosyMovimientos,
    Cotizar, setCotizar,
    AltaUser, setAltaUser,
    Configura, setConfigura,
    Acceso_favorito, setAcceso_favorito,
    userRol, setUserRol,
    email, setEmail,
    tipoBd, settipoBd,
    idContratista, setidContratista,
    usuario, setUsuario,
    openAlert,
    setOpenAlert,
    onCloseCallback,
    setOnCloseCallback,
    carritoItems, 
    setCarritoItems,
    getUserData,
    getToken,
    getUsername,
    logout,

    setStorage,
    getStorage,
    search,setSearch
  }

  return (
    <AuthContext.Provider value={valuesProvider}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}

AuthProvider.defaultProps = {
  children: null
}
