import React, { useState, useEffect, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { Chat } from "components/chat";
import { useAuth } from "hooks/useAuth";
// import { Foto_perfil } from "components/components_perso/Foto_perfil";
// import { Alta_usuario } from "components/Home_Components/Alta_usuario";
// import { CambiarContrasena } from "components/Home_Components/CambiarContrasena";
import { useLocation } from "react-router-dom";

export const Navbar = () => {
  const {
    typeRol,
    Accesocarrito,
    setTypeRol,
    ControlAutorizarCotizaciones,
    verSaldosyMovimientos,
    Cotizar,
    AltaUser,
    Configura,
    Acceso_favorito,
    userRol,
    email,
    tipoBd,
    idContratista,
    usuario,
  } = useAuth();
  const locations = useLocation();
  const [styleNav, setStyleNav] = useState("");
  const [categories, setCategories]= useState([])

  const changeColor = () => {
    //window.alert(document.documentElement.scrollTop)
    if (document.documentElement.scrollTop > 50 && locations.pathname == "/") {
      setStyleNav("navbar-default");
      // window.alert('hola')
    } else if (
      document.documentElement.scrollTop === 0 &&
      locations.pathname == "/"
    ) {
      setStyleNav("navbar-home");
    }
  };

  window.addEventListener("scroll", changeColor);

  useEffect(() => {
    //sendAlert()
    // window.alert(JSON.stringify(locations.pathname))
	getCategories()

    if (locations.pathname == "/") {
      setStyleNav("navbar-home");
    } else {
      setStyleNav("navbar-default");
    }
  }, []);

  const getCategories =async ()=>{
	const result = await fetch_categories()
	if(result){
		setCategories(result.categories)
	}
  }

  const nameProduct = (name) => {
    switch (name) {
      case "Telescopic Boom Lifts":
        name = "Plataformas telescópicas";
        break;
      case "Articulated Boom Lifts":
        name = "Plataformas articuladas";
        break;
      case "Telehandlers":
        name = "Manipuladores telescópicos";
        break;
      case "Aerial Work Platforms":
        name = "Elevadores de personas";
        break;
      case "Vertical Mast Lifts":
        name = "Brazos verticales";
        break;
      case "Material Lifts":
        name = "Elevadores de Material";
        break;
      case "Slab Scissor Lifts":
        name = "Plataformas de tijera eléctricas";
        break;

      case "Trailer Mounted Boom Lifts":
        name = "Plataformas articuladas remolcables";
        break;
      case "Rough Terrain Scissor Lifts":
        name = "Plataformas de tijera todo terreno";
        break;
    }

    return name;
  };
  return (
    <header>
		<Chat/>
      <nav
        class={
          "navbar " + styleNav + " navbar-trans navbar-expand-lg fixed-top"
        }
      >
        <div class="container">
          <a class="navbar-brand text-brand" href="/" className="title-nav">
            Blocks Baratos
          </a>
		
          <div
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            style={{ color: "white", border: "0px" }}
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="#ffff"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>

          <div
            class="navbar-collapse collapse justify-content-center "
            id="navbarDefault"
          >
            {/* <Buscador /> */}

            <div className="row container text-na">
              <div className="col-2 "></div>

              <div className="col-2 ">
                
              </div>
              <div className="col-2 ">
                <Link to="/about" className="title-nav">
                  {" "}
                  Nosotros{" "}
                </Link>
              </div>
              <div className="col-2 ">
                <Link to="/services" className="title-nav">
                  {" "}
                  Servicios{" "}
                </Link>
              </div>

              <div className="col-4">
                
              </div>
            </div>
			<ul className="nav justify-content-end">
				
                  <li class="nav-item dropdown">
                    <a
                      className="dropdown-toggle textls text-selects"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Plataformas
                    </a>
                    <ul class="dropdown-menu">
						{categories &&
						categories.map((item)=>(
							(item.externalId!='19d2f330-235c-4843-949a-296cc770d9af' && item.externalId!='04812c2b-038d-4551-af94-148dcccd140e' &&
								item.externalId!='12946d5d-f729-430d-b4ec-17b6285d1fcb' && item.externalId!='b5d104a5-1fc2-46f2-ae59-70fcd6cf7d54'
								&& item.externalId!='20bbde65-a5d3-4fee-98cf-5f6dfae151ac' && item.externalId!='e88625b3-a563-405c-aa13-88ae77d36f30'
							)?
							<li>
							<a
							  class="dropdown-item "
							  href={'/venta - renta/'+item.nameSpanish+'/'+ item.id+'/todo méxico/0'}
							>
							  {item.nameSpanish}
							</a>
						  </li>:	
						  ""
						))

						}

                    
                    </ul>
                  </li>
                </ul>

            <div style={{ width: "350px", textAlign: "center" }}>
              <div className="navbar-usuario navbar-usuario-t">
                {" "}
                <div className="Menu-in">
                  {/* <a
                    href={
                      "https://api.whatsapp.com/send?phone=5218118000621&text=Hola%2C%20buen%20d%C3%ADa%F0%9F%98%80"
                    }
                    target="_blank"
                  >
                    <i
                      class="fa fa-whatsapp"
                      style={{ fontSize: "20px", color: "#ffff" }}
                      aria-hidden="true"
                    >
                      {" "}
                      (81)-1800-0621{" "}
                    </i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* Falta migrar estos componentes
                
                
                <Foto_perfil/>
			    <Alta_usuario/>
				<CambiarContrasena/>
				<Chat_Help/> */}

      <div
        class="offcanvas offcanvas-start offcan-fondo"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Menu</h5>

          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            data-toggle="modal"
            data-target="#modalFotoPerfil"
          ></div>

<button
            type="button"
            className="btn active"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ffff"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div class="offcanvas-body">
				<h3>Plataformas</h3>
				 
				
					  {categories &&
					  categories.map((item)=>(
						  (item.externalId!='19d2f330-235c-4843-949a-296cc770d9af' && item.externalId!='04812c2b-038d-4551-af94-148dcccd140e' &&
							  item.externalId!='12946d5d-f729-430d-b4ec-17b6285d1fcb' && item.externalId!='b5d104a5-1fc2-46f2-ae59-70fcd6cf7d54'
							  && item.externalId!='20bbde65-a5d3-4fee-98cf-5f6dfae151ac' && item.externalId!='e88625b3-a563-405c-aa13-88ae77d36f30'
						  )?
						  
						  <a
							class="dropdown-item offcan-title" 
              href={'/venta - renta/'+item.nameSpanish+'/'+ item.id+'/todo méxico/0'}
						  >
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
</svg>  {" " +(item.nameSpanish)}
						  </a>
							
						:""
					  ))

					  }
				  
				  
				

          <br />
          <br />
          <br />
        </div>
      </div>
    </header>
  );
};

const fetch_categories = async () =>
    (
      await fetch(
        `${process.env.REACT_APP_URL}search-engine/get-categories-gen`
      )
    ).json();