import "./Banners.css"
import { useNavigate } from "react-router-dom"
export const Banners = ({title, description, sectionActive, url, list, titleSection})=>{
const navigate= useNavigate()
    return (
        <>
        <section className="section_"> 
        <div className="home_banners_"> 
          <div className='row container'>
            <div className="col-md-6">
            <h1 className="main-title-h1_">
             {title}
            <span className="main-title-p_"> {description} </span>
            <br/>
            <button type="button" onClick={()=>{navigate(url)}} className="btn active btn-button-emp" data-bs-toggle="button" aria-pressed="true">{titleSection}</button>
            </h1>
            </div>
            <div className="col-md-6">
            </div>
          </div>
          </div>
        <div className='background_'>
           <div id="carouselExample" class="carousel slide">
                <div class="carousel-inner">
                  {list && list.map((item)=>(
                    <div class={"carousel-item " + (sectionActive==item.id? ' active': '')}>
                    <img src={item.urlImg} className="video_" alt="..." />
                    </div>
                    ))
                  }
                </div>
               
                </div>
        </div>
  
         </section>
        </>
    )
}