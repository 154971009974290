
import './Breamcrumb.css'
export const Breamcrumb=({name, rute, rute2, name2})=>{

    return (
    <div>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href={rute} style={{color:'#b5b5b5'}}>{name}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{name2}</li>
  </ol>
  </div>

    )
}