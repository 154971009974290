import { Navbar } from "ui/components/navbar"
import { Footer } from "ui/components/footer"
import { useEffect } from "react"
export const About = () =>{
    useEffect(()=>{
        window.scrollTo(0, 0);
      
    },[])

    return(
        <>
            <Navbar />
            <div className="container section-padding" >

            <h1 className="section-title"> Nosotros  </h1>

            <br/>

            <p className="text-part">
            En Block Baratos, nos enorgullecemos de ser líderes en la industria de la construcción, ofreciendo soluciones integrales para tus necesidades de elevación. Con una amplia gama de plataformas de elevación disponibles para la venta y renta, somos tu socio confiable para alcanzar nuevas alturas en tus proyectos de construcción.

            </p>

            <p className="text-part">
            Nuestra empresa se compromete a proporcionar equipos de alta calidad y confiables que cumplan con los más altos estándares de seguridad y rendimiento. Ya sea que necesites una plataforma de elevación para tareas de mantenimiento, instalación o construcción, tenemos la solución perfecta para ti.
            </p>

            <p className="text-part">
            En Block Baratos, entendemos que cada proyecto es único, por lo que ofrecemos un servicio personalizado para satisfacer tus requisitos específicos. Nuestro equipo de expertos está aquí para asesorarte en la selección del equipo adecuado y para brindarte soporte técnico en cada etapa del proceso.
            </p>
            <p className="text-part">
            Confía en Block Baratos para proporcionarte las herramientas necesarias para elevar tu negocio al siguiente nivel. 
            </p>

            <br/>
            <br/>
            <br/>
            <br/>
            <p className="text-part text-center">
             <h4>¡Contáctanos hoy mismo y descubre cómo podemos ayudarte a alcanzar nuevas alturas en tus proyectos! </h4>
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
           



            </div>
            <Footer />
        </>

    )
}