

export const getMetaTags = (articule,urlPresentation, urlPage, title, description, urlAlternate, urlCanonical) => {


    const ogType = document.createElement('meta');
     ogType.setAttribute('property', 'og:type');
     ogType.setAttribute('content', articule);
 
     document.head.prepend(ogType);
 
 
     const ogType2 = document.createElement('meta');
     ogType2.setAttribute('property', 'og:image:');
     ogType2.setAttribute('content',urlPresentation);
 
     document.head.prepend(ogType2);
 
     const ogType3 = document.createElement('meta');
     ogType3.setAttribute('property', 'og:url');
     ogType3.setAttribute('content', urlPage);
 
     document.head.prepend(ogType3);
 
     const ogType4 = document.createElement('meta');
     ogType4.setAttribute('property', 'og:title');
     ogType4.setAttribute('content',title);
 
     document.head.prepend(ogType4);
     
     const ogType5 = document.createElement('meta');
     ogType5.setAttribute('property', 'og:description');
     ogType5.setAttribute('content',description);
 
     document.head.prepend(ogType5);
     
     const link2 = document.createElement('link');
     link2.setAttribute('rel', 'alternate');
     link2.setAttribute('href', urlAlternate);
     document.head.appendChild(link2);

     const link = document.createElement('link');
     link.setAttribute('rel', 'canonical');
     link.setAttribute('href', urlCanonical);
     document.head.appendChild(link);

     document.title= title
}