import { Navbar } from "ui/components/navbar";
import { Footer } from "ui/components/footer";
import React, { useEffect, useState } from "react";
import { Breamcrumb } from "components/breamcrumb";
import ListProductAmazon from "components/ListProductAmazon/ListProductAmazon";
import { Search } from "components/search/Search";
import { useParams } from "react-router-dom";
import { getMetaTags } from "helpers/getMetaTags";
export const ProductsAmazons = () => {
  const { name,state,id,action,stateid  } = useParams();
  const nameProduct = (name) => {
    switch (name) {
      case "Telescopic Boom Lifts":
        name = "Plataformas telescópicas";
        break;
      case "Articulated Boom Lifts":
        name = "Plataformas articuladas";
        break;
      case "Telehandlers":
        name = "Manipuladores telescópicos";
        break;
      case "Aerial Work Platforms":
        name = "Elevadores de personas";
        break;
      case "Vertical Mast Lifts":
        name = "Brazos verticales";
        break;
      case "Material Lifts":
        name = "Elevadores de Material";
        break;
      case "Slab Scissor Lifts":
        name = "Plataformas de tijera eléctricas";
        break;

      case "Trailer Mounted Boom Lifts":
        name = "Plataformas articuladas remolcables";
        break;
      case "Rough Terrain Scissor Lifts":
        name = "Plataformas de tijera todo terreno";
        break;
    }

    return convertNameProduct(name);
  };

  const convertNameProduct =(name)=>{
    let i=0;
    while(i==0){
        if(name.includes("-")){
            
            name=name.replace("-", " ");
        }else{
            i=1
        }
    }
    return name;
  }
  let articule='Sección'
  let urlPresentation=`${process.env.REACT_APP_URL_FRONT}img/img_servicios/principal.jpg`
  ///venta%20-%20renta/Brazos%20verticales/5/todo%20méxico/0
  let urlPage=`${process.env.REACT_APP_URL_FRONT}${action}/${name}/${id}/${state}/${stateid}`
  let title ='BLOCKS BARATOS | '+ ((name && state && id && action)?
  (action.charAt(0).toUpperCase()+ action.slice(1)+' de ' +(name ? nameProduct(name) : "plataformas") +' en ' + state)
              :
  ('Las mejores '+(name ? nameProduct(name) : "plataformas") + ' de todo México'))
  let description=((name && state && id && action)?
  (action.charAt(0).toUpperCase()+ action.slice(1)+' de ' +(name ? nameProduct(name) : "plataformas") +' en ' + state)
              :
  ('Las mejores '+(name ? nameProduct(name) : "plataformas") + ' de todo México')) 
  let urlAlternate= `${process.env.REACT_APP_URL_FRONT}renta/${name}/${id}/${state}/${stateid}`
  let urlCanonical=`${process.env.REACT_APP_URL_FRONT}venta/${name}/${id}/${state}/${stateid}`
  useEffect(() => {
    getMetaTags(articule, urlPresentation, urlPage, title,description, urlAlternate, urlCanonical)
    window.scrollTo(0, 0);
  }, []);

 



  return (
    <>
      <Navbar />
      <div className="container" style={{ paddingTop: "80px" }}>
        <Breamcrumb name={"INICIO"} rute={"/home"} name2={"PRODUCTOS "} />
        <h1 style={{ color: "gray" }}>
          {(name && state && id && action)?
            (action.charAt(0).toUpperCase()+ action.slice(1)+' de ' +(name ? nameProduct(name) : "plataformas") +' en ' + state)
                        :
            ('Las mejores '+(name ? nameProduct(name) : "plataformas") + ' de todo México')
          }          
         
        </h1>
        <br />
        <ListProductAmazon />

        <div className="container">
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};
