import { Navbar } from "ui/components/navbar"
import { Footer } from "ui/components/footer"
import { useEffect } from "react"

export const Services = () =>{

    useEffect(()=>{
        window.scrollTo(0, 0);
      
    },[])

    return(
        <>
            <Navbar />
            <div className="container section-padding" >
            <h1 className="section-title"> Nuestros Servicios  </h1>

            <br/>

            <p className="text-part">
            En Block Baratos, ofrecemos una amplia gama de servicios especializados en la renta y venta de plataformas de elevación para satisfacer todas tus necesidades en el área de la construcción. Con años de experiencia en la industria y un compromiso inquebrantable con la calidad y la seguridad, nos enorgullecemos de ser tu socio confiable en cada proyecto. Nuestros servicios incluyen:
            </p>

            <h5>Renta de Plataformas de Elevación</h5>
            <p className="text-part">
            Disponemos de una extensa flota de plataformas de elevación listas para satisfacer tus necesidades de alquiler. Ya sea que estés buscando la versatilidad de las z45 y z60 para trabajos en altura o la eficiencia de las tijeras para áreas de acceso restringido, tenemos la solución perfecta para ti. Nuestras plataformas están bien mantenidas y cumplen con los más altos estándares de seguridad, lo que garantiza un rendimiento óptimo en cada proyecto.
</p>

<h5>Venta de Plataformas de Elevación
</h5>
<p className="text-part">
Para aquellos que buscan una solución a largo plazo, ofrecemos una amplia selección de plataformas de elevación en venta. Desde las z45 y z60 hasta las tijeras y los montacargas, nuestro catálogo cuenta con equipos de primera calidad de marcas líderes en la industria. Nuestro equipo experto está disponible para asesorarte en la selección del equipo adecuado para tus necesidades específicas, garantizando una inversión que impulse el éxito de tus proyectos de construcción.
</p>

<h5>Mantenimiento y Soporte Técnico
</h5>
<p className="text-part">
En Block Baratos, nos comprometemos a garantizar que tus equipos de elevación funcionen de manera óptima en todo momento. Ofrecemos servicios de mantenimiento preventivo y correctivo para mantener tus plataformas en condiciones óptimas y minimizar el tiempo de inactividad. Además, nuestro equipo de soporte técnico está disponible para brindarte asistencia experta en cualquier momento que la necesites, asegurando que tus proyectos se desarrollen sin contratiempos.
</p>

<h5>Venta de Material para la Construcción
</h5>
<p className="text-part">
Además de nuestras soluciones de elevación, también ofrecemos la venta de todo tipo de material para la construcción. Desde bloques y cemento hasta herramientas y equipos de seguridad, tenemos todo lo que necesitas para llevar a cabo tu proyecto de construcción de manera eficiente y segura. Nuestro amplio inventario de materiales de construcción de alta calidad garantiza que encuentres todo lo que necesitas en un solo lugar, simplificando el proceso de abastecimiento y ahorrándote tiempo y recursos.
</p>
<p className="text-part">

</p>

<br/>

<br/>
<br/>
<br/>


</div>
            <Footer />
        </>

    )
}