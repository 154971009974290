
export const Tabs = ({sectionActive, setSectionActive, list})=>{
   
    const changeTab = (section )=>[
        setSectionActive(section)
    ]
return (
    <ul className="nav nav-tabs justify-content-center">
      {list&& 
       list.map((item)=>( 
       <li className="nav-item"  >
          <a className={"nav-link title-services pointer "  + (sectionActive ===item.id ? ' active': '' ) } onClick={()=>{changeTab(item.id)}} aria-current="page" >
            {item.title}
          </a>
        </li>
        ))
      }
    </ul>
    )
} 