export const sendMailService = (mail,name, cooments, cel, subject) => {

    const body = {
        "mail":mail,
        "name":name,
        "body":cooments,
        "phone":cel,
        "subject":'BLOCKS BARATOS | COTIZACIÓN | '+subject
    };

  let requestOpc={
    method: 'POST',
    headers: { 
      'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }
 console.log(JSON.stringify(body))
  return new Promise(resolve => {
   fetch(`${process.env.REACT_APP_URL}search-engine/send-mail`,requestOpc )
      .then((response)=> response.json())
      .then((response) => {
        //alert('respuesta'+ response.token
     
          resolve({
            isSuccess: true,
            message: '',
            response})
           
      })
      .catch((err) => {
      
        const message = 'No se pudo establecer una conexión'
        resolve({
          isSuccess: false,
          message,
          token: null,
          data: null
        })
      })
   
  })
}


//mandar a llamarlo
//export const sendMailServices(mail,name,message, phone).then(({ isSuccess, response, message }) => { })
